
import React, { useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { uct_local, get_order_status_color, IsModemAccess } from '../../helpers/Helper';
import { post } from '../../helpers/api_helper';
import { POST_GET_WEB_PORTALL_LIST, POST_SEARCH_ORDER } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import useMounted from '../../hooks/useMounted';
import Loader from '../../components/Loader';

const UserRoles = (props) => {
    const [isLoader, setLoader] = useState(0);


    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [ShowOrderDetail, SetShowOrderDetail] = useState(false);
    const [webPortalsList, SetWebPortalsList] = useState([]);

    const simpleValidator = useRef(new SimpleReactValidator());

    const [, forceUpdate] = useState();

    const [OrderDetail, SetOrderDetail] = useState({});

    const [form_inputs, setInputs] = useState({
        webPortalId: '',
        orderNumber: '',
    });

    useMounted(() => {
        async function get_detaile() {
            await getFormOption();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const getFormOption = async () => {
        var res_data = await post(POST_GET_WEB_PORTALL_LIST);
        if (res_data.status) {
            SetWebPortalsList(res_data.data.web_portals_list);
        } else {
            toast.error(res_data.message);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        form_inputs[event.target.name] = event.target.value;
        setInputs({ ...form_inputs });
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {

            SetButtonDisabled(true);
            var res_data = await post(POST_SEARCH_ORDER, form_inputs);
            if (res_data.status) {
                SetOrderDetail(res_data.data.order_info);
                SetShowOrderDetail(true);
            } else {
                toast.error(res_data.message);
                SetShowOrderDetail(false);
            }
            SetButtonDisabled(false);
        }
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <h5>Search Order Form</h5>
                                </div>
                                <hr className='mb-3' />
                                <div className='col-sm-12'>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Web Portal *</label>
                                            <select className='form-control' name='webPortalId' value={form_inputs.webPortalId} onChange={handleInputChange} >
                                                <option value="">Select Web Portal</option>
                                                {webPortalsList && webPortalsList.map((value, key) => {
                                                    return (
                                                        <option value={value.webPortalId}>{value.name}</option>
                                                    )
                                                })}
                                            </select>
                                            {simpleValidator.current.message('web portal', form_inputs.webPortalId, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Order No *</label>
                                            <input type='text' name='orderNumber' value={form_inputs.orderNumber} onChange={handleInputChange} className='form-control' />
                                            {simpleValidator.current.message('order number', form_inputs.orderNumber, 'required')}
                                        </div>

                                        <div className='text-center'>
                                            <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                                {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Search
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {ShowOrderDetail &&
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className='row mb-3'>
                                    <div className='col-sm-6'>
                                        <h5 className='m-0'>Order Detaile</h5>
                                    </div>
                                    <div className='col-sm-6 text-right'>
                                    </div>
                                </div>
                                <hr className='mb-3' />
                                <div className='row mb-3'>
                                    <div className='col-sm-4'>
                                        <p className='mb-2'><b>Web Code : </b><br /> {OrderDetail.code}</p>
                                        <p className='mb-2'><b>User Name : </b><br /> {OrderDetail.firstName} {OrderDetail.lastName}</p>
                                        <p className='mb-2'><b>User Email : </b> <br />{OrderDetail.email}</p>
                                        <p className='mb-2'><b>Order Status : </b> <br />
                                            <span className={`badge text-capitalize ${get_order_status_color(OrderDetail.orderStatus)}`}>{OrderDetail.orderStatus}</span>
                                        </p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='mb-2'><b>Order No : </b><br /> {OrderDetail.orderNumber}</p>
                                        <p className='mb-2'><b>Order Date : </b><br /> {uct_local(OrderDetail.dateCreated)}</p>
                                        <p className='mb-2'><b>Order Amount : </b><br /> {OrderDetail.currency} {OrderDetail.total}</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='mb-2'><b>Payment Method : </b><br /> {OrderDetail.paymentMethodTitle}</p>
                                        <p className='mb-2'><b>Payment Method Code : </b><br /> {OrderDetail.paymentMethod}</p>
                                        <p className='mb-2'><b>Transaction Id : </b><br /> {OrderDetail.transactionId}</p>
                                        <p className='mb-2'><b>Payment Date : </b><br /> {uct_local(OrderDetail.paymentDate)}</p>
                                        <p className='mb-2'><b>Payment Completed Date : </b><br /> {uct_local(OrderDetail.paymentCompletedDate)}</p>
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className='col-sm-12'>
                                        <table className='table table-border table-sm'>
                                            <thead>
                                                <tr>
                                                    <th width="50">Sr.</th>
                                                    <th>Product</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {OrderDetail.orderDetail && OrderDetail.orderDetail.map((productDetail, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {productDetail.product_detail && productDetail.product_detail.map((value, index) => (
                                                                <p className='mb-0' key={index}><b>{value.name} : </b> {value.value}</p>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className='col-sm-12'>
                                        <p className='mb-2'><b>User Order Note : </b> </p>
                                        <p className='mb-2'>{OrderDetail.note}</p>
                                    </div>
                                </div>
                                <hr />

                            </div>
                        </div>
                    </div>}


            </div>

            {ShowOrderDetail && <div className='row'>
                <div className='col-sm-12'>
                    <div class="nav-align-top mb-4">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <button
                                    type="button"
                                    class="nav-link active"
                                    role="tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#navs-top-profile"
                                    aria-controls="navs-top-profile"
                                    aria-selected="false">
                                    Web Order Note
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content">

                            <div class="tab-pane fade show active" id="navs-top-profile" role="tabpanel">
                                <table className='table table-sm'>
                                    <tbody>
                                        {OrderDetail.notes_list && OrderDetail.notes_list.map((note, key) => {
                                            return (<tr key={key}>
                                                <td style={{ 'minWidth': '180px' }}>{uct_local(note.date_created)}</td>
                                                <td width="100px">{(note.customer_note) ? 'Customer' : 'Internal'}</td>
                                                <td>{note.note}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                                {OrderDetail.notes_list && OrderDetail.notes_list.length <= 0 &&
                                    <p className='text-center'>No any notes</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

        </>
    );
}

export default UserRoles;