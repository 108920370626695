
import React, { useState, useRef } from 'react';

import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_CHANGE_PASSWORD } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

const ChangePassword = (props) => {

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ old_password: '', password: '', c_password: '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_CHANGE_PASSWORD, form_inputs, {});
            if (res_data.status) {
                setInputs({ old_password: '', password: '', c_password: '' });
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">Change Password</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label for="defaultFormControlInput" className="form-label">Name</label>
                                    <input name="old_password" value={form_inputs.old_password} onChange={handleInputChange} className="form-control" placeholder="Enter Old Password" type="password" />
                                    {simpleValidator.current.message('old password', form_inputs.old_password, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label for="defaultFormControlInput" className="form-label">Name</label>
                                    <input name="password" value={form_inputs.password} onChange={handleInputChange} className="form-control" placeholder="Enter Password" type="password" />
                                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label for="defaultFormControlInput" className="form-label">Name</label>
                                    <input name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="form-control" placeholder="Enter Confirm Password" type="password" />
                                    {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                </div>
                                <div className='mb-3 text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Change Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;