
// auth
export const POST_LOGIN = "/auth/login"
export const POST_VERIFY_OTP = "/auth/verify-otp"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"


export const POST_CHANGE_PASSWORD = "/dashboard/change-password"
export const GET_DASHBOARD_DATA = "/dashboard/dashboard"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"


//user role
export const POST_ROLE_LIST = process.env.REACT_APP_API_URL+"user-role/all"
export const DELETE_USER_ROLE = "/user-role/delete"
export const POST_UPDATE_ROLE_STATUS = "/user-role/update-status"
export const POST_ADD_EDIT_ROLE = "/user-role/add-edit"
export const POST_GET_ROLE_DETAIL = "/user-role/detail"

//Users
export const POST_USERS_LIST = process.env.REACT_APP_API_URL+"user/all"
export const DELETE_USERS = "/user/delete"
export const POST_UPDATE_USERS_STATUS = "/user/update-status"
export const POST_ADD_EDIT_USERS = "/user/add-edit"
export const POST_GET_USERS_DETAIL = "/user/detail"

// site
export const POST_SITE_LIST = process.env.REACT_APP_API_URL+"site/all"
export const DELETE_SITE = "/site/delete"
export const POST_UPDATE_SITE_STATUS = "/site/update-status"
export const POST_ADD_EDIT_SITE = "/site/add-edit"
export const POST_GET_SITE_DETAIL = "/site/detail"

// panel
export const POST_PANEL_LIST = process.env.REACT_APP_API_URL+"panel/all"
export const DELETE_PANEL = "/panel/delete"
export const POST_UPDATE_PANEL_STATUS = "/panel/update-status"
export const POST_ADD_EDIT_PANEL = "/panel/add-edit"
export const POST_GET_PANEL_DETAIL = "/panel/detail"
export const POST_GET_SERVICE_LIST = "/panel/get-service-list"
export const POST_GET_PANEL_LIST = "/panel/get-panel"

// service type
export const POST_SERVICE_TYPE_LIST = process.env.REACT_APP_API_URL+"service-type/all"
export const DELETE_SERVICE_TYPE = "/service-type/delete"
export const POST_UPDATE_SERVICE_TYPE_STATUS = "/service-type/update-status"
export const POST_ADD_EDIT_SERVICE_TYPE = "/service-type/add-edit"
export const POST_GET_SERVICE_TYPE_DETAIL = "/service-type/detail"

// General Options
export const POST_GENERAL_OPTIONS_LIST = process.env.REACT_APP_API_URL+"general-options/all"
export const DELETE_GENERAL_OPTIONS = "/general-options/delete"
export const POST_UPDATE_GENERAL_OPTIONS_STATUS = "/general-options/update-status"
export const POST_ADD_EDIT_GENERAL_OPTIONS = "/general-options/add-edit"
export const POST_GET_GENERAL_OPTIONS_DETAIL = "/general-options/detail"

// email template
export const POST_EMAIL_TEMPLATE_LIST = process.env.REACT_APP_API_URL+"email-template/all"
export const DELETE_EMAIL_TEMPLATE = "/email-template/delete"
export const POST_UPDATE_EMAIL_TEMPLATE_STATUS = "/email-template/update-status"
export const POST_ADD_EDIT_EMAIL_TEMPLATE = "/email-template/add-edit"
export const POST_GET_EMAIL_TEMPLATE_DETAIL = "/email-template/detail"

// web Portal
export const POST_WEB_PORTAL_LIST = process.env.REACT_APP_API_URL+"web-portal/all"
export const DELETE_WEB_PORTAL = "/web-portal/delete"
export const POST_UPDATE_WEB_PORTAL_STATUS = "/web-portal/update-status"
export const POST_GET_WOOCOMMERCE_PORTAL = "/web-portal/get-woocommerce-product"
export const POST_ADD_EDIT_WEB_PORTAL = "/web-portal/add-edit"
export const POST_GET_WEB_PORTAL_DETAIL = "/web-portal/detail"
export const POST_GET_WEB_PORTALL_LIST = "/web-portal/get-web-portal"

// url
export const POST_URL_LIST = process.env.REACT_APP_API_URL+"url/all"
export const DELETE_URL = "/url/delete"
export const POST_UPDATE_URL_STATUS = "/url/update-status"
export const POST_ADD_EDIT_URL = "/url/add-edit"
export const POST_GET_URL_DETAIL = "/url/detail"

// service
export const POST_SERVICE_LIST = process.env.REACT_APP_API_URL+"service/all"
export const DELETE_SERVICEL = "/service/delete"
export const POST_UPDATE_SERVICE_STATUS = "/service/update-status"
export const POST_ADD_EDIT_SERVICEL = "/service/add-edit"
export const POST_GET_SERVICE_DETAIL = "/service/detail"
export const POST_GET_WEB_PORTAL_SERVICE = "/service/get-web-portal-service"


// web service
export const POST_PORTAL_SERVICE_LIST = process.env.REACT_APP_API_URL+"portal-service/all" 
export const DELETE_PORTAL_SERVICE = "/portal-service/delete"
export const POST_UPDATE_PORTAL_SERVICE_STATUS = "/portal-service/update-status"
export const POST_ADD_EDIT_PORTAL_SERVICE = "/portal-service/add-edit"
export const POST_GET_PORTAL_SERVICE_DETAIL = "/portal-service/detail"

//web order
export const POST_WEB_ORDER_LIST = process.env.REACT_APP_API_URL+"portal-order/all"
export const POST_GET_ORDER_DETAIL = "/portal-order/order-detail"
export const POST_SINK_WEB_ORDER = "/portal-order/order-sink"
export const POST_UPDATE_ORDER_STATUS = "/portal-order/update-order-status"
export const POST_ORDER_LOCK_UNLOCK = "/portal-order/order-lock-unlock"
export const POST_EMAIl_SEND = "/portal-order/email-send"
export const POST_SEARCH_ORDER = "/portal-order/search-order"

// smm order
export const POST_SMM_ORDER_LIST = process.env.REACT_APP_API_URL+"smm-order/all"
export const POST_GET_SMM_FORM_OPTION = "/smm-order/smm-order-form"
export const POST_SMM_PLACE_ORDER = "/smm-order/place-order"
export const POST_UPDATE_SMS_ORDER_STATUS = "/smm-order/update-order-status"
export const POST_SMM_SEARCH_ORDER = "/smm-order/search-order"

export const POST_SEARCH_STELLAR_TRANSACTION = "/stellar/search"
