import React from 'react';
import { Outlet } from "react-router-dom";

function Full({ children }) {
    return (
        <>
             <Outlet />
        </>
    );
}

export default Full;