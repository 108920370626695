
import React, { useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { uct_local, get_order_status_color, IsModemAccess } from '../../helpers/Helper';
import { post } from '../../helpers/api_helper';
import { POST_SEARCH_STELLAR_TRANSACTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const UserRoles = (props) => {
    const [isLoader, setLoader] = useState(1);


    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [ShowOrderDetail, SetShowOrderDetail] = useState(false);

    const simpleValidator = useRef(new SimpleReactValidator());

    const [, forceUpdate] = useState();

    const [TransactionList, SetTransactionList] = useState([]);

    const [form_inputs, setInputs] = useState({
        searchType: '',
        publicKey: '',
        memoTag: '',
        hashTag: '',
    });

    // input text change handler
    const handleInputChange = (event) => {
        form_inputs[event.target.name] = event.target.value;
        setInputs({ ...form_inputs });

        simpleValidator.current.hideMessages()
        forceUpdate(event.target.value);
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_SEARCH_STELLAR_TRANSACTION, form_inputs);
            if (res_data.status) {
                SetTransactionList(res_data.data.transaction);
                SetShowOrderDetail(true);
            } else {
                toast.error(res_data.message);
                SetShowOrderDetail(false);
            }
            SetButtonDisabled(false);
        }
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <h5>Search Stellar Transaction Form</h5>
                                </div>
                                <hr className='mb-3' />
                                <div className='col-sm-12'>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Search Type *</label>
                                            <select className='form-control' name='searchType' value={form_inputs.searchType} onChange={handleInputChange} >
                                                <option value="">Select Search Type</option>
                                                <option value="mamoTag">Search by Memo Tag</option>
                                                <option value="hash">Search by Hash</option>
                                            </select>
                                            {simpleValidator.current.message('search type', form_inputs.searchType, 'required')}
                                        </div>

                                        {form_inputs.searchType == 'mamoTag' && <>
                                            <div className='mb-3'>
                                                <label className="form-label text-capitalize">Public Key *</label>
                                                <input type='text' name='publicKey' value={form_inputs.publicKey} onChange={handleInputChange} className='form-control' />
                                                {simpleValidator.current.message('public key', form_inputs.publicKey, 'required')}
                                            </div>
                                            <div className='mb-3'>
                                                <label className="form-label text-capitalize">Memo Tag *</label>
                                                <input type='text' name='memoTag' value={form_inputs.memoTag} onChange={handleInputChange} className='form-control' />
                                                {simpleValidator.current.message('memo tag', form_inputs.memoTag, 'required')}
                                            </div>
                                        </>}
                                        {form_inputs.searchType == 'hash' && <>
                                            <div className='mb-3'>
                                                <label className="form-label text-capitalize">Hash Tag *</label>
                                                <input type='text' name='hashTag' value={form_inputs.hashTag} onChange={handleInputChange} className='form-control' />
                                                {simpleValidator.current.message('hash tag', form_inputs.hashTag, 'required')}
                                            </div>
                                        </>}

                                        <div className='text-center'>
                                            <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                                {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Search
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {ShowOrderDetail && <div className='row'>
                <div className='col-sm-12'>
                    <div class="nav-align-top mb-4">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <button
                                    type="button"
                                    class="nav-link active"
                                    role="tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#navs-top-profile"
                                    aria-controls="navs-top-profile"
                                    aria-selected="false">
                                    Transaction List
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content">

                            <div class="tab-pane fade show active" id="navs-top-profile" role="tabpanel">
                                <div className='table-responsive'>
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <td>Id</td>
                                                <td>Date</td>
                                                <td>Status</td>
                                                <td>Type</td>
                                                <td>XLM</td>
                                                <td>USD</td>
                                                <td>Hash</td>
                                                <td>From</td>
                                                <td>To</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {TransactionList && TransactionList.map((tr, key) => {
                                                return (<tr key={key}>
                                                    <td>{tr.id}</td>
                                                    <td>{uct_local(tr.created_at)}</td>
                                                    <td width="100px">{(tr.transaction_successful) ? 'Success' : 'Pending'}</td>
                                                    <td>{tr.type}</td>
                                                    <td>{tr.amount}</td>
                                                    <td>${tr.usd_amount}</td>
                                                    <td>{tr.transaction_hash}</td>
                                                    <td>{tr.from}</td>
                                                    <td>{tr.to}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {TransactionList && TransactionList.length <= 0 &&
                                    <p className='text-center'>No any notes</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

        </>
    );
}

export default UserRoles;