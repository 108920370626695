
import React, { useState } from 'react';
import useMounted from '../hooks/useMounted';
import { post } from '../helpers/api_helper';
import { get_order_status_color, amountFormat } from '../helpers/Helper';
import { GET_DASHBOARD_DATA } from '../helpers/url_helper';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Dashboard = (props) => {
    const [orderStatus, SetOrderStatus] = useState([]);
    const [smmOrderStatus, SetSmmOrderStatus] = useState([]);
    const [webPortalsList, SetWebPortalsList] = useState([]);
    const [smmPanelList, SetSmmPanelList] = useState([]);

    useMounted(() => {
        async function get_detaile() {
            var res_data = await post(GET_DASHBOARD_DATA, {});
            if (res_data.status) {
                SetOrderStatus(res_data.data.order_status_list);
                SetSmmOrderStatus(res_data.data.smm_order_status_list);
                SetWebPortalsList(res_data.data.web_portals_list);
                SetSmmPanelList(res_data.data.smm_panels_list);
            } else {
                toast.error(res_data.message);
            }
        }
        get_detaile();
    }, [])

    return (
        <>
            <div className='row'>
                <div class="col-lg-12 col-xxl-12 mb-4">
                    <div class="card h-100">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <div class="card-title mb-0">
                                <h5 class="m-0 me-2">Web Overall order</h5>
                            </div>
                        </div>
                        <div class="card-body">
                            <ul class="p-0 m-0 row">
                                {orderStatus && orderStatus.map((status, key) => {
                                    return (
                                        <li class="d-flex mb-4 pb-1 col-sm-3" key={key}>
                                            <div class="avatar flex-shrink-0 me-3">
                                                <span class={`avatar-initial rounded  ${get_order_status_color(status.status).replace('bg-', 'bg-label-')}`}><i class="bx bx-package"></i></span>
                                            </div>
                                            <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div class="me-2">
                                                    <h6 class="mb-1 fw-normal">{status.status}</h6>
                                                    <p className='mb-0 text-black'><b>${amountFormat(status.total_amount)}</b></p>
                                                </div>
                                                <div class="user-progress">
                                                    <h5 class="mb-0 text-black1">{status.total_order}</h5>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div class="col-lg-12 col-xxl-12 mb-4">
                    <div class="card h-100">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <div class="card-title mb-0">
                                <h5 class="m-0 me-2">Smm Overall order</h5>
                            </div>
                        </div>
                        <div class="card-body">
                            <ul class="p-0 m-0 row">
                                {smmOrderStatus && smmOrderStatus.map((status, key) => {
                                    return (
                                        <li class="d-flex mb-4 pb-1 col-sm-3" key={key}>
                                            <div class="avatar flex-shrink-0 me-3">
                                                <span class={`avatar-initial rounded  ${get_order_status_color(status.status).replace('bg-', 'bg-label-')}`}><i class="bx bx-package"></i></span>
                                            </div>
                                            <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div class="me-2">
                                                    <h6 class="mb-1 fw-normal">{status.status}</h6>
                                                    <p className='mb-0 text-black'><b>${amountFormat(status.total_amount)}</b></p>
                                                </div>
                                                <div class="user-progress">
                                                    <h5 class="mb-0 text-black1">{status.total_order}</h5>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div class="col-md-12 col-lg-12 mb-4">
                    <div class="card h-100">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <h5 class="card-title m-0 me-2">Web Order Overview </h5>
                            <div class="dropdown"></div>
                        </div>
                        <div class="card-body">
                            <ul class="p-0 m-0 row">
                                {webPortalsList && webPortalsList.map((web, key) => {
                                    return (
                                        <li class="d-flex mb-4 pb-1 col-sm-3" key={key}>
                                            <div class="avatar flex-shrink-0 me-3">
                                                <Link to={web.url} target="_blank">
                                                    <span class="avatar-initial rounded bg-label-primary"><i class="tf-icons bx bx-globe"></i></span>
                                                </Link>
                                            </div>
                                            <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div class="me-2">
                                                    <h6 class="mb-0">{web.name}</h6>
                                                    <p className='mb-0 text-black'><b>${amountFormat(web.total_amount)}</b></p>
                                                </div>
                                                <div class="user-progress d-flex align-items-center gap-1">
                                                    <h5 class="mb-0 text-black1">{web.total_order}</h5>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                             
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div class="col-md-12 col-lg-12 mb-4">
                    <div class="card h-100">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <h5 class="card-title m-0 me-2">SMM Panel Overview </h5>
                            <div class="dropdown"></div>
                        </div>
                        <div class="card-body">
                            <ul class="p-0 m-0 row">
                                {smmPanelList && smmPanelList.map((web, key) => {
                                    return (
                                        <li class="d-flex mb-4 pb-1 col-sm-3" key={key}>
                                            <div class="avatar flex-shrink-0 me-3">
                                                <Link to={web.url} target="_blank">
                                                    <span class="avatar-initial rounded bg-label-primary"><i class="tf-icons bx bx-globe"></i></span>
                                                </Link>
                                            </div>
                                            <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div class="me-2">
                                                    <h6 class="mb-0">{web.name}</h6>
                                                    <p className='mb-0 text-black'><b>${amountFormat(web.balance)} USD</b></p>
                                                </div>
                                                <div class="user-progress d-flex align-items-center gap-1">
                                                    <h5 class="mb-0 text-black1"></h5>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                             
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                {/* <div class="col-sm-6 col-lg-3 mb-4">
                    <div class="card card-border-shadow-primary h-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-2 pb-1">
                                <div class="avatar me-2">
                                    <span class="avatar-initial rounded bg-label-primary"><i class="tf-icons bx bx-dollar"></i></span>
                                </div>
                                <h4 class="ms-1 mb-0">42</h4>
                            </div>
                            <p class="mb-1">Total Amount</p>

                        </div>
                    </div>
                </div> */}
                {/* <div class="col-sm-6 col-lg-3 mb-4">
                    <div class="card card-border-shadow-warning h-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-2 pb-1">
                                <div class="avatar me-2">
                                    <span class="avatar-initial rounded bg-label-warning"><i class="bx bx-error"></i></span>
                                </div>
                                <h4 class="ms-1 mb-0">8</h4>
                            </div>
                            <p class="mb-1">Vehicles with errors</p>
                            <p class="mb-0">
                                <span class="fw-medium me-1">-8.7%</span>
                                <small class="text-muted">than last week</small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3 mb-4">
                    <div class="card card-border-shadow-danger h-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-2 pb-1">
                                <div class="avatar me-2">
                                    <span class="avatar-initial rounded bg-label-danger"><i class="bx bx-git-repo-forked"></i></span>
                                </div>
                                <h4 class="ms-1 mb-0">27</h4>
                            </div>
                            <p class="mb-1">Deviated from route</p>
                            <p class="mb-0">
                                <span class="fw-medium me-1">+4.3%</span>
                                <small class="text-muted">than last week</small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3 mb-4">
                    <div class="card card-border-shadow-info h-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-2 pb-1">
                                <div class="avatar me-2">
                                    <span class="avatar-initial rounded bg-label-info"><i class="bx bx-time-five"></i></span>
                                </div>
                                <h4 class="ms-1 mb-0">13</h4>
                            </div>
                            <p class="mb-1">Late vehicles</p>
                            <p class="mb-0">
                                <span class="fw-medium me-1">-2.5%</span>
                                <small class="text-muted">than last week</small>
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default Dashboard;