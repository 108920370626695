
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useMounted  from './../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';
import { toast } from 'react-toastify';


const Setting = (props) => {
    const navigate = useNavigate();

    useMounted(() => {
        if (!IsModemAccess('setting', 'edit')) {
            toast.error('Does not permission this module');
            navigate("/");
        }
    })

    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-4">
                    <div className="card">
                        <Link to="/setting/email">
                        <div className="card-body">
                            <h3 className="card-title text-nowrap mb-3">Email Setting</h3>
                            <span className="d-block mb-0">CONFIGURE</span>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-4">
                    <div className="card">
                        <Link to="/setting/common">
                        <div className="card-body">
                            <h3 className="card-title text-nowrap mb-3">Common Setting</h3>
                            <span className="d-block mb-0">CONFIGURE</span>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;