
import React, { useState } from 'react';

import { getToken, IsModemAccess, uct_local, get_smm_order_status_color } from '../../helpers/Helper';
import { post } from '../../helpers/api_helper';
import { POST_SMM_ORDER_LIST, POST_UPDATE_SMS_ORDER_STATUS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import useMounted from '../../hooks/useMounted';
import { Link } from 'react-router-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/dataTables.dataTables.css";


const UserRoles = (props) => {

    const [ButtonDisabled, SetButtonDisabled] = useState(false);


    useMounted(() => {
        get_order_list()
    })

    const get_order_list = () => {
        const tableElement = $('#dataTableExample');
        if (tableElement.length > 0) {
            tableElement.DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": false,
                "sServerMethod": "POST",
                "order": [[0, 'desc']],
                columnDefs: [
                    { orderable: false, targets: [] },
                    { "targets": 0, "visible": false }
                ],
                "ajax": function (data, callback, settings) {
                    data.sSearch = data.search.value;
                    data.iDisplayLength = data.length;
                    data.iDisplayStart = data.start;
                    data.sSortDir_0 = (data.order.length > 0) ? data.order[0].dir : '';
                    data.iSortCol_0 = (data.order.length > 0) ? data.order[0].column : '';

                    $.ajax({
                        "url": POST_SMM_ORDER_LIST,
                        "type": "POST",
                        "data": data,
                        "headers": { 'Authorization': getToken() },
                        "success": callback
                    });
                },
                "columns": [
                    { "data": "smmOrderId" },
                    { "data": "code" },
                    { "data": "smmOrderNumber" },
                    { "data": "webCode" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <a href="javascript:void(0);"  onClick={() => { window.open('/web-orders/view/' + data.orderId,'_blank'); }}>{data.webOrderNo}</a>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt)}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <p className='mb-0 break-word'>{data.smmServiceName}</p>
                            </>, td)
                        }
                    },
                    { "data": "charge" },
                    { "data": "quantity" },
                    { "data": "remainsQuantity" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <span className={`badge text-capitalize ${get_smm_order_status_color(data.smmOrderStatus)}`}>{data.smmOrderStatus}</span>
                            </>, td)
                        }
                    },
                   
                ]
            });
        }
    }

    // Sync Orders
    const UpdateOrderStatus = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_SMS_ORDER_STATUS);
        if (res_data.status) {
            toast(res_data.message);
            get_order_list();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-sm-6'>
                                    <h5 className='mt-1'>Smm Orders</h5>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    {IsModemAccess('web_order', 'edit') && <Link to="search" className='btn btn-sm btn-primary'> Search Order</Link>} &nbsp; &nbsp; &nbsp;
                                    {IsModemAccess('web_order', 'edit') && <button onClick={UpdateOrderStatus} disabled={ButtonDisabled} type='button' className='btn btn-sm btn-primary'> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update Status</button>}
                                </div>
                            </div>
                            <hr />
                           
                            <div className='table-responsive text-nowrap'>
                                {IsModemAccess('web_order', 'view') &&
                                    <table id="dataTableExample" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td>Id</td>
                                                <td>Smm Panel</td>
                                                <td>Smm Order No.</td>
                                                <td>Web Code.</td>
                                                <td>Web Order No.</td>
                                                <td>Order Date</td>
                                                <td>Service Name</td>
                                                <td>Charge</td>
                                                <td>Quantity</td>
                                                <td>Remains</td>
                                                <td>Status</td>
                                            </tr>
                                        </thead>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserRoles;