
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';
import CreatableSelect from 'react-select/creatable';


import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_GENERAL_OPTIONS_DETAIL, POST_ADD_EDIT_GENERAL_OPTIONS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ id: 0, name: '', value: []});
    const [, forceUpdate] = useState();

    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (id) {
                if (!IsModemAccess('general_options', 'edit')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
                var res_data = await post(POST_GET_GENERAL_OPTIONS_DETAIL, { 'id': id });
                if (res_data.status) {
                    setInputs(res_data.data.detail);
                } else {
                    toast.error(res_data.message);
                    navigate("/");
                }
            } else {
                if (!IsModemAccess('general_options', 'add')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            }
            setLoader(1)
        }

        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_GENERAL_OPTIONS, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                navigate("/general-options");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name == 'name'){
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value.trim() }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    const handleChange = (newValue = []) => {
		setInputs(inputs => ({ ...form_inputs, ['value']: newValue }));
		console.groupEnd();
	};

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">General Options</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Name *</label>
                                    <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                    {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Value *</label>
                                    <CreatableSelect
                                        defaultValue={form_inputs.value}
                                        isMulti
                                        onChange={handleChange}
                                    />
                                    {simpleValidator.current.message('value', form_inputs.value, 'required')}
                                </div>

                                <div className='text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save
                                    </button>
                                    <Link to="/general-options" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;