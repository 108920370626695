
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';


import SimpleReactValidator from 'simple-react-validator';
import { get, post } from '../../helpers/api_helper';
import { POST_GET_ROLE_DETAIL, POST_ADD_EDIT_ROLE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);
    const [title, setTitle] = useState('Add User Role');

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ rolesId: 0, roleName: '', roleAssigned: [] });
    const [userRole, setuserRole] = useState([]);
    const [, forceUpdate] = useState();

    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (id) {
                if (!IsModemAccess('use_role', 'edit')) {
                    toast.error('Does not permission this module');
                    navigate("/");
                }
                setTitle('Edit User Role');
            } else {
                if (!IsModemAccess('use_role', 'add')) {
                    toast.error('Does not permission this module');
                    navigate("/");
                }
            }

            var res_data = await post(POST_GET_ROLE_DETAIL, { 'rolesId': id });
            if (res_data.status) {
                setInputs(res_data.data.role_info);
                setuserRole(res_data.data.user_role);
            } else {
                toast.error(res_data.message);
                navigate("/");
            }
            setLoader(1)
        }

        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.roleAssigned = userRole
            var res_data = await post(POST_ADD_EDIT_ROLE, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                navigate("/user-roles");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    const handleInputChangeRole = (key, event) => {
        event.persist();
        var data = userRole;
        data[key].module[event.target.name] = (event.target.checked) ? 1 : 0;
        setuserRole(data);
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">User Role</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Name *</label>
                                    <input name="roleName" value={form_inputs.roleName} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                    {simpleValidator.current.message('name', form_inputs.roleName, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <table className="align-middle mb-0 table">
                                        <thead>
                                            <tr>
                                                <th>Module Name</th>
                                                <th>Add</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                                <th>View</th>
                                                <th>Show Menu</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(userRole).map(([key, value]) => (
                                                <tr>
                                                    <td>{value.name}</td>
                                                    <td>
                                                        {value.module.add === 3 && "-"}
                                                        {value.module.add !== 3 &&
                                                            <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="add" onChange={(event) => handleInputChangeRole(key, event)} defaultChecked={value.module.add} type="checkbox" className="form-check-input" />
                                                            </div>}
                                                    </td>
                                                    <td>
                                                        {value.module.edit === 3 && "-"}
                                                        {value.module.edit !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                            <input name="edit" onChange={(event) => handleInputChangeRole(key, event)} defaultChecked={value.module.edit} type="checkbox" className="form-check-input" />
                                                        </div>}
                                                    </td>
                                                    <td>
                                                        {value.module.delete === 3 && "-"}
                                                        {value.module.delete !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                            <input name="delete" onChange={(event) => handleInputChangeRole(key, event)} defaultChecked={value.module.delete} type="checkbox" className="form-check-input" />
                                                        </div>}
                                                    </td>
                                                    <td>
                                                        {value.module.view === 3 && "-"}
                                                        {value.module.view !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                            <input name="view" onChange={(event) => handleInputChangeRole(key, event)} defaultChecked={value.module.view} type="checkbox" className="form-check-input" />
                                                        </div>}
                                                    </td>
                                                    <td>
                                                        {value.module.action === 3 && "-"}
                                                        {value.module.action !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                            <input name="action" onChange={(event) => handleInputChangeRole(key, event)} defaultChecked={value.module.action} type="checkbox" className="form-check-input" />
                                                        </div>}
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save
                                    </button>
                                    <Link to="/user-roles" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;