
import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { post, get } from '../../helpers/api_helper';
import { POST_UPDATE_SETTING, GET_ALL_SETTING } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();


    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();


    useMounted(() => {
        if (!IsModemAccess('setting', 'edit')) {
            toast.error('Does not permission this module');
            navigate("/");
        }
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])


    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + "?type=email-setting");
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
        if (res_data.status) {
            await get_app_setting();
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        if(event.target.name == 'email_enable'){
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked)? 1 : 0 }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">Email Setting</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">SMTP Host</label>
                                    <input name="email_host" value={form_inputs.email_host} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP Host`} type="text" />
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">SMTP Port</label>
                                    <input name="email_port" value={form_inputs.email_port} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP Port`} type="text" />
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">SMTP FROM</label>
                                    <input name="email_from" value={form_inputs.email_from} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP From`} type="text" />
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">SMTP User Name</label>
                                    <input name="email_user_name" value={form_inputs.email_user_name} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP User Name`} type="text" />
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">SMTP Password</label>
                                    <input name="email_password" value={form_inputs.email_password} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP Password`} type="password" />
                                </div>
                                <div className='mb-3'>
                                    <div className="form-check form-switch mb-2">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Email Enable</label>
                                        <input className="form-check-input" defaultChecked={parseInt(form_inputs.email_enable)} name='email_enable' onChange={handleInputChange} type="checkbox" id="flexSwitchCheckDefault" />
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update
                                    </button>
                                    <Link to="/setting" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;