
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';


import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_USERS_DETAIL, POST_ADD_EDIT_USERS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);
    const [title, setTitle] = useState('Add User');

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ userId: 0, rolesId: '', firstName: '', lastName: '', email: '', password: '', c_password: '' });
    const [userTypeList, setUserTypeList] = useState([]);
    const [, forceUpdate] = useState();

    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (id) {
                if (!IsModemAccess('user', 'edit')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
                setTitle('Edit User');
            } else {
                if (!IsModemAccess('user', 'add')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            }

            var res_data = await post(POST_GET_USERS_DETAIL, { 'userId': id });
            if (res_data.status) {
                setInputs(res_data.data.user_detail);
                setUserTypeList(res_data.data.user_role);
            } else {
                toast.error(res_data.message);
                navigate("/");
            }
            setLoader(1)
        }

        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_USERS, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                navigate("/users");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">Users</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">First Name *</label>
                                    <input name="firstName" value={form_inputs.firstName} onChange={handleInputChange} className="form-control" placeholder="Enter First Name" type="text" />
                                    {simpleValidator.current.message('first name', form_inputs.firstName, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Last Name *</label>
                                    <input name="lastName" value={form_inputs.lastName} onChange={handleInputChange} className="form-control" placeholder="Enter Last Name" type="text" />
                                    {simpleValidator.current.message('last name', form_inputs.lastName, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Select User Role *</label>
                                    <select name="rolesId" value={form_inputs.rolesId} onChange={handleInputChange} className="form-control">
                                        <option value="">Select user role</option>
                                        {Object.entries(userTypeList).map(([key, value]) => (
                                            <option key={key} value={value.rolesId}>{value.roleName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Email *</label>
                                    <input name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter Email Address" type="text" />
                                    {simpleValidator.current.message('last name', form_inputs.email, 'required|email')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Password</label>
                                    <input name="password" value={form_inputs.password} onChange={handleInputChange} className="form-control" placeholder="Enter Password" type="text" />
                                    {!form_inputs.userId && simpleValidator.current.message('password', form_inputs.password, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Confirm Password</label>
                                    <input name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="form-control" placeholder="Enter Confirm Password" type="text" />
                                    {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                </div>

                                <div className='text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save
                                    </button>
                                    <Link to="/users" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;