
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';


import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_PORTAL_SERVICE_DETAIL, POST_ADD_EDIT_PORTAL_SERVICE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ portalServicId: 0, name: '', smmSitesId: '', category: '', service: '', price: '', regularPrice: '', salePrice: '', stockStatus: '', webPortal: [] });
    const [selectedOption, setSelectedOption] = useState(null);


    const [sitesList, SetSitesList] = useState([]);
    const [categoryList, SetCategoryList] = useState([]);
    const [serviceList, SetServiceList] = useState([]);
    const [webPortalsList, SetWebPortalsList] = useState([]);
    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (id) {
                if (!IsModemAccess('web_portal_services', 'edit')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            } else {
                if (!IsModemAccess('web_portal_services', 'add')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            }

            var res_data = await post(POST_GET_PORTAL_SERVICE_DETAIL, { 'portalServicId': id });
            if (res_data.status) {
                if (res_data.data.services_detail) {
                    setInputs(res_data.data.services_detail);
                }
                SetSitesList(res_data.data.smm_sites_list)
                SetCategoryList(res_data.data.category_list)
                SetServiceList(res_data.data.service_list)
                SetWebPortalsList(res_data.data.web_portals_list)
            } else {
                toast.error(res_data.message);
                navigate("/");
            }
            setLoader(1)
        }

        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_PORTAL_SERVICE, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                navigate("/web-service");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }
    
    const handleInputChangeWebProduct = (index, event) => {
        console.log(index);
        console.log(event.target.name);
        console.log(event.target.value);
        form_inputs.webPortal[index][event.target.name] = event.target.value;

        setInputs({...form_inputs});
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-12">
                    <div className="card mb-4">
                        <h5 className="card-header">Web Portal Service </h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Name *</label>
                                            <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                            {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Sites *</label>
                                            <select name='smmSitesId' onChange={handleInputChange} value={form_inputs.smmSitesId} className="form-control">
                                                <option value="">Select Site</option>
                                                {sitesList && sitesList.map((value, index) => (
                                                    <option value={value.siteId} key={index}>{value.name}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('sites', form_inputs.smmSitesId, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Category *</label>
                                            <select name='category' onChange={handleInputChange} value={form_inputs.category} className="form-control">
                                                <option value="">Select Category</option>
                                                {categoryList && categoryList.map((value, index) => (
                                                    <option value={value} key={index}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('category', form_inputs.category, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Service *</label>
                                            <select name='service' onChange={handleInputChange} value={form_inputs.service} className="form-control">
                                                <option value="">Select Service</option>
                                                {serviceList && serviceList.map((value, index) => (
                                                    <option value={value} key={index}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('service', form_inputs.service, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Web Portal *</label>
                                            <Select
                                                name='webPortal'
                                                defaultValue={form_inputs.webPortal}
                                                isMulti
                                                onChange={(val) => { handleInputChange({ target: { name: 'webPortal', value: val } }) }}
                                                options={webPortalsList}
                                            />
                                            {simpleValidator.current.message('web portal', form_inputs.webPortal, 'required')}
                                        </div>
                                        {form_inputs.webPortal && form_inputs.webPortal.map((value, index) => (
                                            <div className='mb-3' key={index}>
                                                <label className="form-label text-capitalize">{value.label} *</label>
                                                <select name='selectService' onChange={handleInputChangeWebProduct.bind(this,index)} value={value.selectService}  className="form-control">
                                                    <option value="">Select Product</option>
                                                    {value.services && value.services.map((product, index) => (
                                                        <option value={product.id} key={index}>[{product.id}] [Price : {product.price}] {product.name}</option>
                                                    ))}
                                                </select>
                                                {simpleValidator.current.message('service', value.selectService, 'required')}
                                            </div>
                                        ))}
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Price *</label>
                                            <input name="price" value={form_inputs.price} onChange={handleInputChange} className="form-control" placeholder="Enter Price" type="text" />
                                            {simpleValidator.current.message('price', form_inputs.price, 'required|numeric')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Regular Price *</label>
                                            <input name="regularPrice" value={form_inputs.regularPrice} onChange={handleInputChange} className="form-control" placeholder="Enter Regular Price" type="text" />
                                            {simpleValidator.current.message('regular price', form_inputs.regularPrice, 'required|numeric')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Sale Price *</label>
                                            <input name="salePrice" value={form_inputs.salePrice} onChange={handleInputChange} className="form-control" placeholder="Enter Regular Price" type="text" />
                                            {simpleValidator.current.message('sale price', form_inputs.salePrice, 'required|numeric')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Stock Status *</label>
                                            <select name='stockStatus' onChange={handleInputChange} value={form_inputs.stockStatus} className="form-control">
                                                <option value="">Select Stock Status</option>
                                                <option value="instock">Instock</option>
                                                <option value="outofstock">Out of Stock</option>
                                            </select>
                                            {simpleValidator.current.message('stock status', form_inputs.stockStatus, 'required')}
                                        </div>
                                    </div>
                                </div>



                                <div className='text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save
                                    </button>
                                    <Link to="/web-service" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;