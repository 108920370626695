
import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { post, get } from '../../helpers/api_helper';
import { POST_UPDATE_SETTING, GET_ALL_SETTING } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();


    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();


    useMounted(() => {
        if (!IsModemAccess('setting', 'edit')) {
            toast.error('Does not permission this module');
            navigate("/");
        }
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])


    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + "?type=common-setting");
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
        if (res_data.status) {
            await get_app_setting();
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        
        if(event.target.name == 'auto_order_enable' || event.target.name == 'sink_web_automatic_order' || event.target.name == 'web_automatic_update_order_status' || event.target.name == 'smm_update_automatic_service' || event.target.name == 'check_valid_url'){
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked)? 1 : 0 }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">Common Setting</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <div className="form-check form-switch mb-2">
                                        <label className="form-check-label" htmlFor="checkbox1">Automate Order Process from Web to SMM Panel</label>
                                        <input className="form-check-input" defaultChecked={parseInt(form_inputs.auto_order_enable)} name='auto_order_enable' onChange={handleInputChange} type="checkbox" id="checkbox1" />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="form-check form-switch mb-2">
                                        <label className="form-check-label" htmlFor="checkbox2">Daily Sync for SMM Services List</label>
                                        <input className="form-check-input" defaultChecked={parseInt(form_inputs.smm_update_automatic_service)} name='smm_update_automatic_service' onChange={handleInputChange} type="checkbox" id="checkbox2" />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="form-check form-switch mb-2">
                                        <label className="form-check-label" htmlFor="checkbox3">Sync Web Orders Automatically from Web to Manage</label>
                                        <input className="form-check-input" defaultChecked={parseInt(form_inputs.sink_web_automatic_order)} name='sink_web_automatic_order' onChange={handleInputChange} type="checkbox" id="checkbox3" />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="form-check form-switch mb-2">
                                        <label className="form-check-label" htmlFor="checkbox4">Automate Web Order Status Updates</label>
                                        <input className="form-check-input" defaultChecked={parseInt(form_inputs.web_automatic_update_order_status)} name='web_automatic_update_order_status' onChange={handleInputChange} type="checkbox" id="checkbox4" />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="form-check form-switch mb-2">
                                        <label className="form-check-label" htmlFor="checkbox5">Validate URL before Processing Orders</label>
                                        <input className="form-check-input" defaultChecked={parseInt(form_inputs.check_valid_url)} name='check_valid_url' onChange={handleInputChange} type="checkbox" id="checkbox5" />
                                    </div>
                                </div>
                                <div className='text-center mt-5'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update
                                    </button>
                                    <Link to="/setting" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;