
import React, { useState } from 'react';

import { getToken, IsModemAccess, uct_local, get_order_status_color } from '../../helpers/Helper';
import { post } from '../../helpers/api_helper';
import { POST_WEB_ORDER_LIST, POST_SINK_WEB_ORDER, POST_UPDATE_ORDER_STATUS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import useMounted from '../../hooks/useMounted';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/dataTables.dataTables.css";
import { Link, useNavigate } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const UserRoles = (props) => {
    const navigate = useNavigate();

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ orderId: 0, orderStatus: '', noteType : 'internal' });
    const [filter_option, setFilterOption] = useState({status: '', startDate : '', endDate : ''});
    // const [filter_option, setFilterOption] = useState({status: '', startDate : new Date( new Date().getFullYear(),  new Date().getMonth(), 1), endDate : new Date()});


    useMounted(() => {
        get_order_list()
    })

    const get_order_list = () => {
        const tableElement = $('#dataTableExample');
        if (tableElement.length > 0) {
            tableElement.DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": false,
                "sServerMethod": "POST",
                "order": [[0, 'desc']],
                columnDefs: [
                    { orderable: false, targets: [] },
                    { "targets": 0, "visible": false }
                ],
                "ajax": function (data, callback, settings) {
                    data.sSearch = data.search.value;
                    data.iDisplayLength = data.length;
                    data.iDisplayStart = data.start;
                    data.sSortDir_0 = (data.order.length > 0) ? data.order[0].dir : '';
                    data.iSortCol_0 = (data.order.length > 0) ? data.order[0].column : '';
                    data.orderStatus = filter_option.status;
                    data.startDate = (filter_option.startDate)? new Date(filter_option.startDate).getTime() : '';
                    data.endDate = (filter_option.endDate)? new Date(filter_option.endDate).getTime() : '';

                    $.ajax({
                        "url": POST_WEB_ORDER_LIST,
                        "type": "POST",
                        "data": data,
                        "headers": { 'Authorization': getToken() },
                        "success": callback
                    });
                },
                "columns": [
                    { "data": "orderId" },
                    { "data": "code" },
                    { "data": "orderNumber" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.dateCreated)}
                            </>, td)
                        }
                    },
                    { "data": "userName" },
                    { "data": "email" },
                    { "data": "total" },
                    { "data": "paymentMethod" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <span className={`badge text-capitalize ${get_order_status_color(data.orderStatus)}`}>{data.orderStatus}</span>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {/* {IsModemAccess('web_order', 'edit') && <> <button className="btn btn-sm text-primary mr-2" data-bs-toggle="modal" data-bs-target="#EditModal" onClick={() => {setInputs({orderId: data.orderId, orderStatus: data.orderStatus.toLowerCase()})}} ><i className="bx bx-edit"></i></button> </>} */}

                                <button className="btn btn-sm text-info"  onClick={() => { navigate('/web-orders/view/' + data.orderId); }}><i className="bx bx-show align-middle"></i></button>
                                &nbsp; &nbsp; &nbsp;
                                {data.orderType == 'Auto' && <i className="text-danger bx bx-lock align-middle ml-3"></i>}
                                {data.orderType == 'Manual' && <i className="text-success bx bx-lock-open align-middle"></i>}
                            </>, td)
                        }
                    },
                ]
            });
        }
    }

    // Sync Orders
    const SinkOrder = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_SINK_WEB_ORDER);
        if (res_data.status) {
            toast(res_data.message);
            get_order_list();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_ORDER_STATUS, form_inputs);
        if (res_data.status) {
            toast(res_data.message);
            $('.modal-close').click();
            get_order_list();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

     // input text change handler
    const handleInputChangeFilter = (event) => {
        console.log(event);
        setFilterOption(inputs => ({ ...filter_option, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-sm-6'>
                                    <h5 className='mt-1'>Web Orders</h5>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    {IsModemAccess('web_order', 'edit') && <Link to="search" className='btn btn-sm btn-primary'> Search Order</Link>}
                                    &nbsp; &nbsp; &nbsp;
                                    {IsModemAccess('web_order', 'edit') && <button onClick={SinkOrder} disabled={ButtonDisabled} type='button' className='btn btn-sm btn-primary'> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}Sync Orders</button>}
                                </div>
                            </div>
                            <hr />
                            <div className='row mt-4 mb-3'>
                                <div className='col-sm-3'>
                                    <DatePicker name='startDate' className='form-control' 
                                    selected={filter_option.startDate} onChange={(date) => {handleInputChangeFilter({target : {name : 'startDate', value : date}})}} 
                                    placeholderText="Start Date"
                                    dateFormat="dd/MM/YYYY"
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <DatePicker name='endDate' className='form-control' 
                                    selected={filter_option.endDate} onChange={(date) => {handleInputChangeFilter({target : {name : 'endDate', value : date}})}}
                                    placeholderText="End Date"
                                    dateFormat="dd/MM/YYYY"
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <select name='status' className='form-control' value={filter_option.status} onChange={handleInputChangeFilter}>
                                        <option value="">All</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Blockchainpending">Blockchain Pending</option>
                                        <option value="Processing">Processing</option>
                                        <option value="On-Hold">On-Hold</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Refunded">Refunded</option>
                                        <option value="Failed">Failed</option>
                                        <option value="In-Process">In-Process</option>
                                        <option value="In-Review">In-Review</option>
                                    </select>
                                </div>
                                <div className='col-sm-3'>
                                    <button type='button' onClick={get_order_list} className='btn btn-primary'>Apply</button>
                                </div>
                            </div>
                            <div className='table-responsive text-nowrap'>
                                {IsModemAccess('web_order', 'view') &&
                                    <table id="dataTableExample" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td>Id</td>
                                                <td>Web Code</td>
                                                <td>Order Number</td>
                                                <td>Order Date</td>
                                                <td>User Name</td>
                                                <td>User Email</td>
                                                <td>Order Amount</td>
                                                <td>Payment Method</td>
                                                <td>Order Status</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Order Status</h5>
                            <button type="button" className="btn btn-sm close modal-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="bx bx-x"></i>
                            </button>
                        </div>
                        <hr className='mb-0' />
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Order Status *</label>
                                            <select name='orderStatus' value={form_inputs.orderStatus} onChange={handleInputChange} className="form-control" >
                                                <option value="pending">Pending</option>
                                                <option value="processing">Processing</option>
                                                <option value="in-process">In Process</option>
                                                <option value="in-review">In Review</option>
                                                <option value="on-hold">On-Hold</option>
                                                <option value="completed">Completed</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="refunded">Refunded</option>
                                                <option value="failed">Failed</option>
                                            </select>
                                        </div>
                                        <div className='mb-3'>
                                            <label>Note Type</label>
                                            <select name='noteType' value={form_inputs.noteType} onChange={handleInputChange} className="form-control" >
                                                <option value="customer">Customer</option>
                                                <option value="internal">Internal</option>
                                            </select>
                                        </div>
                                        <div className='text-center pt-4'>
                                            <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update </button>
                                            <button type='button' data-bs-dismiss="modal" aria-label="Close" className="btn btn-danger ms-3"> Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserRoles;