import { useEffect, useRef } from 'react';

const useMounted = (callback) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            callback();
        }
    }, [callback]);
};

export default useMounted;