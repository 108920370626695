
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';


import { post } from '../../helpers/api_helper';
import { POST_GET_PANEL_DETAIL, POST_GET_SERVICE_LIST } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ smmPanelId: 0, name: '', code: '', url: '', apiUrl: '', apiKey: '' });

    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (!IsModemAccess('panel', 'view')) {
                toast.error('Does not permission this module');
                navigate("/dashboard");
            }
            await get_detail();
            setLoader(1)
        }

        get_detaile();
    }, [])

    const get_detail = async () => {
        var res_data = await post(POST_GET_PANEL_DETAIL, { 'smmPanelId': id });
        if (res_data.status) {
            setInputs(res_data.data.panels_detail);
        } else {
            toast.error(res_data.message);
            navigate("/");
        }
    }

      //fetchServices
    const fetchServices = async () => {
        if (!IsModemAccess('web_portal', 'edit')) {
            toast.error('Does not permission this module');
            return;
        }
        SetButtonDisabled(true);
        var res_data = await post(POST_GET_SERVICE_LIST, { smmPanelId: id });
        if (res_data.status) {
            toast(res_data.message);
            get_detail();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
        
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-12">
                    <div className="card mb-4">
                        <h5 className="card-header">SMM Panel</h5>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <b>Name</b>
                                    <p>{form_inputs.name}</p>
                                </div>
                                <div className='col-sm-4'>
                                    <b>Code</b>
                                    <p>{form_inputs.code}</p>
                                </div>
                                <div className='col-sm-4'>
                                    <b>Url</b>
                                    <p><Link to={form_inputs.url} target='_blank'>{form_inputs.url}</Link></p>
                                </div>
                                <div className='col-sm-4'>
                                    <b>Api Url </b>
                                    <p>{form_inputs.apiUrl}</p>
                                </div>
                                <div className='col-sm-4'>
                                    <b>Api Key </b>
                                    <p>{form_inputs.apiKey}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className="col-md-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-sm-6'>
                                    <h5 className='mb-0 mt-2'>Services List</h5>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    <button type='button' disabled={ButtonDisabled} onClick={fetchServices} className='btn btn-primary'>
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i> } 
                                        {!ButtonDisabled &&  <i className="bx bx-refresh mr-2"></i> }
                                        &nbsp; Fetch Services
                                    </button>
                                </div>
                            </div>
                            <hr/>
                            <div className='row'>
                                {form_inputs.serviceList && form_inputs.serviceList.map((services, index) => (
                                    <div className='col-sm-4 mb-3' key={index}>
                                        <div className='border p-3 border rounded'>
                                            <p className='mb-1'><b>service :</b> {services.service}</p>
                                            <p className='mb-1'><b>Name :</b> {services.name}</p>
                                            <p className='mb-1'><b>Type :</b> {services.type}</p>
                                            <p className='mb-1'><b>Category :</b> {services.category}</p>
                                            <p className='mb-1'><b>Rate :</b> {services.rate}</p>
                                            <p className='mb-1'><b>Min :</b> {services.min}</p>
                                            <p className='mb-1'><b>Max :</b> {services.max}</p>
                                            <p className='mb-1'><b>Refill :</b> {services.refill}</p>
                                            <p className='mb-1'><b>Cancel :</b> {services.cancel}</p>
                                        </div>
                                    </div>
                                ))}
                                {!form_inputs.serviceList && 
                                    <div className='col-sm-12' >
                                        <p className='text-center'> No any services</p>
                                    </div>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;