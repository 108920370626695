
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { getToken, IsModemAccess } from '../../helpers/Helper';
import { del, post } from '../../helpers/api_helper';
import { POST_USERS_LIST, DELETE_USERS, POST_UPDATE_USERS_STATUS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import useMounted from '../../hooks/useMounted';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/dataTables.dataTables.css";


const UserRoles = (props) => {
    const navigate = useNavigate();
    const [userId, SetUserId] = useState(0);


    useMounted(() => {
        get_user()
    })

    const get_user = () => {
        const tableElement = $('#dataTableExample');
        if (tableElement.length > 0) {
            tableElement.DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": false,
                "sServerMethod": "POST",
                "order": [[0, 'desc']],
                columnDefs: [
                    { orderable: false, targets: [] },
                    { "targets": 0, "visible": false }
                ],
                "ajax": function (data, callback, settings) {
                    data.sSearch = data.search.value;
                    data.iDisplayLength = data.length;
                    data.iDisplayStart = data.start;
                    data.sSortDir_0 = (data.order.length > 0) ? data.order[0].dir : '';
                    data.iSortCol_0 = (data.order.length > 0) ? data.order[0].column : '';

                    $.ajax({
                        "url": POST_USERS_LIST,
                        "type": "POST",
                        "data": data,
                        "headers": { 'Authorization': getToken() },
                        "success": callback
                    });
                },
                "columns": [
                    { "data": "userId" },
                    { "data": "name" },
                    { "data": "email" },
                    { "data": "roleName" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={UpdateStatus.bind(this, data.userId, data.userStatus)} className={`border-0 badge ${(data.userStatus == 'Active') ? 'bg-success' : 'bg-danger'}`}>{data.userStatus}</button>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>

                                {IsModemAccess('user', 'edit') && <> <button className="btn btn-sm text-primary mr-2" onClick={() => { navigate('/users/edit/' + data.userId); }} ><i className="bx bx-edit"></i></button> </>}

                                {IsModemAccess('user', 'delete') && <button className="btn btn-sm text-danger" onClick={() => { SetUserId(data.userId); }} data-bs-toggle="modal" data-bs-target="#deleteModal" ><i className="bx bx-trash-alt align-middle"></i></button>}
                            </>, td)
                        }
                    },
                ]
            });
        }
    }

    //UpdateStatus
    const UpdateStatus = async (userId, status) => {
        if (!IsModemAccess('user', 'edit')) {
            toast.error('Does not permission this module');
            return;
        }
        var res_data = await post(POST_UPDATE_USERS_STATUS, { userId: userId, status: status });
        if (res_data.status) {
            toast(res_data.message);
            get_user();
        } else {
            toast.error(res_data.message);
        }
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_USERS + "?userId=" + userId);
        if (res_data.status) {
            toast(res_data.message);
            get_user();
        } else {
            toast.error(res_data.message);
        }
    }

    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-12 col-lg-7 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-sm-6'>
                                    <h5 className='mt-1'>Users</h5>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    {IsModemAccess('user', 'add') && <Link to='/users/add' className='btn btn-sm btn-primary'>+ Add</Link>}
                                </div>
                            </div>
                            <hr />
                            <div className='table-responsive text-nowrap'>
                                {IsModemAccess('user', 'view') &&
                                    <table id="dataTableExample" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td>Id</td>
                                                <td>Name</td>
                                                <td>Email</td>
                                                <td>Role</td>
                                                <td>Status</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="btn btn-sm close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="bx bx-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-bs-dismiss="modal" className="btn btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserRoles;