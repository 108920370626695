import React from 'react';

function Loader(props) {

    return (
        <>
            <div class="loader-wrapper">
                <div class="loader"></div>
            </div>
        </>
    );
}

export default Loader;