import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Full from './layouts/Full'; // Assuming Full is your layout component
import Default from './layouts/Default'; // Assuming Full is your layout component

import Login from './page/Auth/Login';
import ForgotPassword from './page/Auth/ForgotPassword';

import Dashboard from './page/Dashboard';
import ChangePassword from './page/Auth/ChangePassword';

import Setting from './page/Setting/Setting';
import EmailSetting from './page/Setting/EmailSetting';
import CommonSetting from './page/Setting/CommonSetting';

import UserRolesList from './page/UserRoles/UserRolesList';
import UserRoles from './page/UserRoles/UserRoles';

import UserList from './page/User/UserList';
import User from './page/User/User';

import SiteList from './page/Site/SiteList';
import Site from './page/Site/Site';

import PanelList from './page/Panel/PanelList';
import Panel from './page/Panel/Panel';
import PanelView from './page/Panel/PanelView';

import ServiceTypeList from './page/SmmServiceType/ServiceTypeList';
import ServiceType from './page/SmmServiceType/ServiceType';

import WebPortalList from './page/WebPortals/WebPortalList';
import WebPortal from './page/WebPortals/WebPortal';
import WebPortalView from './page/WebPortals/WebPortalView';

import WebServicList from './page/WebServic/WebServicList';
import WebServic from './page/WebServic/WebServic';

import UrlList from './page/Url/UrlList';
import Url from './page/Url/Url';

import ServicList from './page/Service/ServiceList';
import Servic from './page/Service/Service';

import GeneralOptionsList from './page/GeneralOptions/GeneralOptionsList';
import GeneralOptions from './page/GeneralOptions/GeneralOptions';

import EmailTemplateList from './page/EmailTemplate/EmailTemplateList';
import EmailTemplate from './page/EmailTemplate/EmailTemplate';

import WebOrders from './page/Orders/WebOrders';
import WebOrdersDetail from './page/Orders/WebOrdersDetail';
import WebOrdersSearch from './page/Orders/WebOrdersSearch';

import SmmOrders from './page/SmmOrders/SmmOrders';
import SmmOrdersSearch from './page/SmmOrders/SmmOrdersSearch';

import StellarTransactionSearch from './page/StellarTransaction/Search';

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Full />,
            children: [
                {
                    path: "",
                    element: <Login />,
                },
                {
                    path: "/forgot-password",
                    element: <ForgotPassword />,
                },

            ],
        },
        {
            path: "/",
            element: <Default />,
            children: [
                {
                    path: "dashboard",
                    element: <Dashboard />,
                },
                {
                    path: "change-password",
                    element: <ChangePassword />,
                },
                {
                    path: "setting",
                    children: [
                        {
                            path: "",
                            element: <Setting />,
                        },
                        {
                            path: "email",
                            element: <EmailSetting />,
                        },
                        {
                            path: "common",
                            element: <CommonSetting />,
                        },
                    ]
                },
                {
                    path: "user-roles",
                    children: [
                        {
                            path: "",
                            element: <UserRolesList />,
                        },
                        {
                            path: "add",
                            element: <UserRoles />,
                        },
                        {
                            path: "edit/:id",
                            element: <UserRoles />,
                        }
                    ]
                },
                {
                    path: "users",
                    children: [
                        {
                            path: "",
                            element: <UserList />,
                        },
                        {
                            path: "add",
                            element: <User />,
                        },
                        {
                            path: "edit/:id",
                            element: <User />,
                        }
                    ]
                },
                {
                    path: "site",
                    children: [
                        {
                            path: "",
                            element: <SiteList />,
                        },
                        {
                            path: "add",
                            element: <Site />,
                        },
                        {
                            path: "edit/:id",
                            element: <Site />,
                        }
                    ]
                },
                {
                    path: "panel",
                    children: [
                        {
                            path: "",
                            element: <PanelList />,
                        },
                        {
                            path: "add",
                            element: <Panel />,
                        },
                        {
                            path: "edit/:id",
                            element: <Panel />,
                        },
                        {
                            path: "view/:id",
                            element: <PanelView />,
                        }
                    ]
                },
                {
                    path: "service-type",
                    children: [
                        {
                            path: "",
                            element: <ServiceTypeList />,
                        },
                        {
                            path: "add",
                            element: <ServiceType />,
                        },
                        {
                            path: "edit/:id",
                            element: <ServiceType />,
                        }
                    ]
                },
                {
                    path: "web-portal",
                    children: [
                        {
                            path: "",
                            element: <WebPortalList />,
                        },
                        {
                            path: "add",
                            element: <WebPortal />,
                        },
                        {
                            path: "edit/:id",
                            element: <WebPortal />,
                        },
                        {
                            path: "view/:id",
                            element: <WebPortalView />,
                        }
                    ]
                },
                {
                    path: "general-options",
                    children: [
                        {
                            path: "",
                            element: <GeneralOptionsList />,
                        },
                        {
                            path: "add",
                            element: <GeneralOptions />,
                        },
                        {
                            path: "edit/:id",
                            element: <GeneralOptions />,
                        }
                    ]
                },
                {
                    path: "web-service",
                    children: [
                        {
                            path: "",
                            element: <WebServicList />,
                        },
                        {
                            path: "add",
                            element: <WebServic />,
                        },
                        {
                            path: "edit/:id",
                            element: <WebServic />,
                        }
                    ]
                },
                {
                    path: "url",
                    children: [
                        {
                            path: "",
                            element: <UrlList />,
                        },
                        {
                            path: "add",
                            element: <Url />,
                        },
                        {
                            path: "edit/:id",
                            element: <Url />,
                        }
                    ]
                },
                {
                    path: "services",
                    children: [
                        {
                            path: "",
                            element: <ServicList />,
                        },
                        {
                            path: "add",
                            element: <Servic />,
                        },
                        {
                            path: "edit/:id",
                            element: <Servic />,
                        }
                    ]
                },
                {
                    path: "email-template",
                    children: [
                        {
                            path: "",
                            element: <EmailTemplateList />,
                        },
                        {
                            path: "add",
                            element: <EmailTemplate />,
                        },
                        {
                            path: "edit/:id",
                            element: <EmailTemplate />,
                        }
                    ]
                },
                {
                    path: "web-orders",
                    children: [
                        {
                            path: "",
                            element: <WebOrders />,
                        },
                        {
                            path: "view/:id",
                            element: <WebOrdersDetail />,
                        },
                        {
                            path: "search",
                            element: <WebOrdersSearch />,
                        }
                    ]
                },
                {
                    path: "smm-orders",
                    children: [
                        {
                            path: "",
                            element: <SmmOrders />,
                        },
                        {
                            path: "search",
                            element: <SmmOrdersSearch />,
                        }
                    ]
                },
                {
                    path: "search-stellar-transaction",
                    children: [
                        {
                            path: "",
                            element: <StellarTransactionSearch />,
                        }
                    ]
                },
            ],
        }

    ])
    return (
        <RouterProvider router={router} />
    );
}

export default App;