
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';


import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_SITE_DETAIL, POST_ADD_EDIT_SITE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);
    const [title, setTitle] = useState('Add Site');

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ siteId: 0, name: '', code: '', url: '' });
    const [, forceUpdate] = useState();

    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (id) {
                if (!IsModemAccess('site', 'edit')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
                setTitle('Edit User');
                var res_data = await post(POST_GET_SITE_DETAIL, { 'siteId': id });
                if (res_data.status) {
                    setInputs(res_data.data.detail);
                } else {
                    toast.error(res_data.message);
                    navigate("/");
                }
            } else {
                if (!IsModemAccess('site', 'add')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            }
            setLoader(1)
        }

        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_SITE, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                navigate("/site");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'image') {
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['png','jpg', 'jpeg'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Alludu only file png, jpg and jpeg.');
                event.target.value = null;
                return false;
            }

            if (file_size > 1) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            }
            reader.readAsDataURL(file);
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">Social Media Sites</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Name *</label>
                                    <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                    {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Code *</label>
                                    <input name="code" value={form_inputs.code} onChange={handleInputChange} className="form-control" placeholder="Enter Code" type="text" />
                                    {simpleValidator.current.message('code', form_inputs.code, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Url *</label>
                                    <input name="url" value={form_inputs.url} onChange={handleInputChange} className="form-control" placeholder="Enter url" type="text" />
                                    {simpleValidator.current.message('url', form_inputs.url, 'required|url')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Image <small className='ml-4'>Alludu file png, jpg and jpeg. Size 300px X 300px</small></label>
                                    <input name="image" onChange={handleInputChange} className="form-control" type="file" accept="image/*" />
                                </div>
                                {form_inputs.image_url && <div className='mb-3 text-right'>
                                    <img src={form_inputs.image_url} alt='logo' width={100} />
                                </div>}

                                <div className='text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save
                                    </button>
                                    <Link to="/site" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;