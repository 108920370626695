
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';


import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_WEB_PORTAL_DETAIL, POST_ADD_EDIT_WEB_PORTAL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);
    const [title, setTitle] = useState('Add web portal');

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ webPortalId: 0, name: '', code: '', url: '', apiUrl: '', apiKey: '', apiSecret : '', memoTagPrefix : '', autoOrderEnable : 1});
    const [, forceUpdate] = useState();

    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (id) {
                if (!IsModemAccess('web_portal', 'edit')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
                setTitle('Edit User');
                var res_data = await post(POST_GET_WEB_PORTAL_DETAIL, { 'webPortalId': id });
                if (res_data.status) {
                    res_data.data.panels_detail.memoTagPrefix = parseInt(res_data.data.panels_detail.memoTagPrefix)
                    setInputs(res_data.data.panels_detail);
                } else {
                    toast.error(res_data.message);
                    navigate("/");
                }
            } else {
                if (!IsModemAccess('web_portal', 'add')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            }
            setLoader(1)
        }

        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_WEB_PORTAL, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                navigate("/web-portal");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name == 'autoOrderEnable'){
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: ( event.target.checked)? 1 : '' }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <div className="card mb-4">
                        <h5 className="card-header">Web Portal</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Name *</label>
                                    <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                    {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Code *</label>
                                    <input name="code" value={form_inputs.code} onChange={handleInputChange} className="form-control" placeholder="Enter Code" type="text" />
                                    {simpleValidator.current.message('code', form_inputs.code, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Memo Tag Prefix *</label>
                                    <input name="memoTagPrefix" maxLength={2} value={form_inputs.memoTagPrefix} onChange={handleInputChange} className="form-control" placeholder="Enter Memo Tag Prefix" type="text" />
                                    {simpleValidator.current.message('memo tag prefix', form_inputs.memoTagPrefix, 'required|numeric')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Url *</label>
                                    <input name="url" value={form_inputs.url} onChange={handleInputChange} className="form-control" placeholder="Enter url" type="text" />
                                    {simpleValidator.current.message('url', form_inputs.url, 'required|url')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Api Url *</label>
                                    <input name="apiUrl" value={form_inputs.apiUrl} onChange={handleInputChange} className="form-control" placeholder="Enter Api Url" type="text" />
                                    {simpleValidator.current.message('api url', form_inputs.apiUrl, 'required|url')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Api Key *</label>
                                    <input name="apiKey" value={form_inputs.apiKey} onChange={handleInputChange} className="form-control" placeholder="Enter Api Key" type="text" />
                                    {simpleValidator.current.message('api key', form_inputs.apiKey, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label text-capitalize">Api Secret *</label>
                                    <input name="apiSecret" value={form_inputs.apiSecret} onChange={handleInputChange} className="form-control" placeholder="Enter Api Secret" type="text" />
                                    {simpleValidator.current.message('api secret', form_inputs.apiSecret, 'required')}
                                </div>
                                <div className='mb-3'>
                                    <div className="form-check form-switch mb-2">
                                        <label className="form-check-label" htmlFor="checkbox1">Auto Order Enable</label>
                                        <input className="form-check-input" defaultChecked={parseInt(form_inputs.autoOrderEnable)} name='autoOrderEnable' onChange={handleInputChange} type="checkbox" id="checkbox1" />
                                    </div>
                                </div>
                                

                                <div className='text-center'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save
                                    </button>
                                    <Link to="/web-portal" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;