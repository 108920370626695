import React, { useState, useRef,useEffect } from 'react';
import '../../assets/vendor/css/pages/page-auth.css'
import { Link, useNavigate } from 'react-router-dom';

import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { getToken, setUserSession } from '../../helpers/Helper';
import { POST_FORGOT_PASSWORD, POST_RESET_PASSWORD } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Auth_Img from './../../assets/img/auth_img.png';

const ForgotPassword = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (getToken()) {
            navigate("/dashboard");
        }
    }, [])

    const simpleValidator = useRef(new SimpleReactValidator());
    const OtpValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [otp_screen, SetOTP_screen] = useState(false);
    const [form_inputs, setInputs] = useState({ email: '', password: '', c_password: '', otp: '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_FORGOT_PASSWORD, { 'email': form_inputs.email }, {});
            if (res_data.status) {
                SetOTP_screen(true);
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const verifyOtp = async (event) => {
        event.preventDefault();
        const formValid = OtpValidator.current.allValid()
        if (!formValid) {
            OtpValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_RESET_PASSWORD, form_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                navigate("/dashboard");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <div>

            </div>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <img src={Auth_Img} className="img-fluid" alt="Login image" width="700" />
                        </div>
                    </div>

                    <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-5">
                                    <span className="app-brand-logo demo">

                                        <svg width="25" viewBox="0 0 25 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                            <defs>
                                                <path d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z" id="path-1"></path>
                                                <path d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z" id="path-3"></path>
                                                <path d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z" id="path-4"></path>
                                                <path d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z" id="path-5"></path>
                                            </defs>
                                            <g id="g-app-brand" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                                                    <g id="Icon" transform="translate(27.000000, 15.000000)">
                                                        <g id="Mask" transform="translate(0.000000, 8.000000)">
                                                            <mask id="mask-2" fill="white">
                                                                <use href="#path-1"></use>
                                                            </mask>
                                                            <use fill="#696cff" href="#path-1"></use>
                                                            <g id="Path-3" mask="url(#mask-2)">
                                                                <use fill="#696cff" href="#path-3"></use>
                                                                <use fillOpacity="0.2" fill="#FFFFFF" href="#path-3"></use>
                                                            </g>
                                                            <g id="Path-4" mask="url(#mask-2)">
                                                                <use fill="#696cff" href="#path-4"></use>
                                                                <use fillOpacity="0.2" fill="#FFFFFF" href="#path-4"></use>
                                                            </g>
                                                        </g>
                                                        <g id="Triangle" transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) ">
                                                            <use fill="#696cff" href="#path-5"></use>
                                                            <use fillOpacity="0.2" fill="#FFFFFF" href="#path-5"></use>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </span>
                                    <span className="app-brand-text demo text-body fw-bold">SMM Manager</span>
                            </div>
                            <h4 className="mb-2">Forgot Password? 🔒</h4>
                            <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p>
                            {!otp_screen && <>
                                <form id="formAuthentication" className="mb-3"  onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label for="email" className="form-label">Email</label>
                                        <input name="email" className="form-control" value={form_inputs.email} onChange={handleInputChange} placeholder="Enter Email Address" type="text" autoFocus />
                                        {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                    </div>
                                    <button disabled={ButtonDisabled} className="btn btn-primary w-100">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Send Reset Link</button>
                                </form>
                                <div className="text-center">
                                    <Link to="/" className="d-flex align-items-center justify-content-center">
                                        <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                                        Back to login
                                    </Link>
                                </div>
                            </>}

                            {otp_screen && <>
                                <form id="formAuthentication" className="mb-3" onSubmit={verifyOtp}>
                                    <div className="mb-3">
                                        <label for="email" className="form-label">Otp</label>
                                        <input name="otp" className="form-control" maxLength="6" value={form_inputs.otp} onChange={handleInputChange} placeholder="Enter OTP" type="text" />
                                        {OtpValidator.current.message('otp', form_inputs.otp, 'required|numeric|min:6')}
                                    </div>
                                    <div className="mb-3">
                                        <label for="email" className="form-label">Password</label>
                                        <input name="password" className="form-control" value={form_inputs.password} onChange={handleInputChange} placeholder="Enter New Password" type="password" />
                                        {OtpValidator.current.message('password', form_inputs.password, 'required')}
                                    </div>
                                    <div className="mb-3">
                                        <label for="email" className="form-label">Confirm Password</label>
                                        <input name="c_password" className="form-control" value={form_inputs.c_password} onChange={handleInputChange} placeholder="Enter Confirm Password" type="password" />
                                        {OtpValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                    </div>
                                    <button disabled={ButtonDisabled} className="btn btn-primary w-100">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Change Password</button>
                                </form>
                                <div className="text-center">
                                    <Link to="/" className="d-flex align-items-center justify-content-center">
                                        <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                                        Back to login
                                    </Link>
                                </div>
                            </>}


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ForgotPassword;