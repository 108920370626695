import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { getToken } from '../helpers/Helper';

// component
import Header from './../components/Header';
import SideNavbar from './../components/SideNavbar';

function Default() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!getToken()) {
            navigate("/");
        }
    }, [])

    window.scrollTo(0, 0);
    const [MenuHide, setMenuHide] = useState('');

    const handleMenu = () => {
        if (MenuHide) {
            setMenuHide('');
        } else {
            setMenuHide('layout-menu-expanded');
        }
    }

    return (
        <>
            <div className={MenuHide}>
                <div className="layout-wrapper layout-content-navbar">
                    <div className="layout-container">
                        <SideNavbar onChangeHandleMenu={handleMenu}/>
                        <div className="layout-page">
                            <Header onChangeHandleMenu={handleMenu}/>
                            <div className="container-fluid flex-grow-1 container-p-y">
                                <Outlet />
                            </div>
                        </div>
                    </div>

                    <div className="layout-overlay layout-menu-toggle"></div>
                </div>
            </div>
        </>
    );
}

export default Default;