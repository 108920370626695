
import React, { useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { uct_local, get_smm_order_status_color } from '../../helpers/Helper';
import { post } from '../../helpers/api_helper';
import { POST_GET_PANEL_LIST, POST_SMM_SEARCH_ORDER } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import useMounted from '../../hooks/useMounted';
import Loader from '../../components/Loader';

const UserRoles = (props) => {
    const [isLoader, setLoader] = useState(0);


    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [ShowOrderDetail, SetShowOrderDetail] = useState(false);
    const [webPanelList, SetPanelList] = useState([]);

    const simpleValidator = useRef(new SimpleReactValidator());

    const [, forceUpdate] = useState();

    const [OrderDetail, SetOrderDetail] = useState({});

    const [form_inputs, setInputs] = useState({
        smmPanelId: '',
        orderNumber: '',
    });

    useMounted(() => {
        async function get_detaile() {
            await getFormOption();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const getFormOption = async () => {
        var res_data = await post(POST_GET_PANEL_LIST);
        if (res_data.status) {
            SetPanelList(res_data.data.panels_list);
        } else {
            toast.error(res_data.message);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        form_inputs[event.target.name] = event.target.value;
        setInputs({ ...form_inputs });
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {

            SetButtonDisabled(true);
            var res_data = await post(POST_SMM_SEARCH_ORDER, form_inputs);
            if (res_data.status) {
                SetOrderDetail(res_data.data.order_info);
                SetShowOrderDetail(true);
            } else {
                toast.error(res_data.message);
                SetShowOrderDetail(false);
            }
            SetButtonDisabled(false);
        }
    }

    if (!isLoader) {
        return (<Loader />);
    }
    return (
        <>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <h5>Search Order Form</h5>
                                </div>
                                <hr className='mb-3' />
                                <div className='col-sm-12'>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Select Web Portal *</label>
                                            <select className='form-control' name='smmPanelId' value={form_inputs.smmPanelId} onChange={handleInputChange} >
                                                <option value="">Select Web Portal</option>
                                                {webPanelList && webPanelList.map((value, key) => {
                                                    return (
                                                        <option value={value.smmPanelId}>{value.name}</option>
                                                    )
                                                })}
                                            </select>
                                            {simpleValidator.current.message('smm portal', form_inputs.smmPanelId, 'required')}
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label text-capitalize">Order No *</label>
                                            <input type='text' name='orderNumber' value={form_inputs.orderNumber} onChange={handleInputChange} className='form-control' />
                                            {simpleValidator.current.message('order number', form_inputs.orderNumber, 'required')}
                                        </div>

                                        <div className='text-center'>
                                            <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                                {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Search
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {ShowOrderDetail &&
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className='row mb-3'>
                                    <div className='col-sm-6'>
                                        <h5 className='m-0'>Order Detaile</h5>
                                    </div>
                                    <div className='col-sm-6 text-right'>
                                    </div>
                                </div>
                                <hr className='mb-3' />
                                <div className='row mb-3'>
                                    <div className='col-sm-4'>
                                        <p className='mb-2'><b>Panel Code : </b><br /> {OrderDetail.panelCode}</p>
                                        <p className='mb-2'><b>Order Status : </b> <br />
                                            <span className={`badge text-capitalize ${get_smm_order_status_color(OrderDetail.status)}`}>{OrderDetail.status}</span>
                                        </p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='mb-2'><b>Order No : </b><br /> {OrderDetail.orderNo}</p>
                                        <p className='mb-2'><b>Order Amount : </b><br /> $ {OrderDetail.charge} {OrderDetail.currency}</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='mb-2'><b>Remains Qty : </b><br /> {OrderDetail.remains}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>}


            </div>

        </>
    );
}

export default UserRoles;